body {
  font-family: 'Baloo Tamma 2', sans-serif;
  background-color: rgb(255, 245, 230);
  color: #333;
  margin: 0;
}

.page {
  padding: 20px;
  background-color: rgb(255, 245, 230);
}

.sectionHeader {
  font-size: 1.2em;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.studentHomeContainer {
  display: flex;
  gap: 20px;
  padding: 20px;
}

.welcomeBox {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 25%;
}

.welcomeBox h3 {
  margin-top: 0;
}

.welcomeBox hr {
  border: 0.5px solid #ddd;
}

/* Upcoming Assignments Header */
.upcoming-assignments {
  color: rgb(241, 139, 2);
  border-bottom: none;
  font-size: 24px;
}

/* Style for the due date text */
.due-date-text {
  color: black;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: left;
}

/* Assignment Container */
.assignment-container {
  display: flex;
  justify-content: space-between;
  align-items: stretch; /* Ensure child elements stretch to full height */
  background-color: white;
  border-radius: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  overflow: hidden; /* Ensure content stays inside the container */
  height: auto; /* Adjusts height based on content */
}

/* Horizontal Divider between assignments */
.assignment-divider {
  border: none;
  height: 1px;
  background-color: #ccc; /* Light grey line */
  margin: 15px 0;
}

/* Class Name Section */
.class-name {
  background-color: rgb(241, 139, 2); /* Orange background */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 0 0 25%; /* Fixed width (25%) of the container */
  padding: 10px; /* Adds padding inside the orange section */
  font-size: 20px; /* Default font size */
  overflow-wrap: break-word; /* Ensures long words break to the next line */
  word-wrap: break-word; /* Wrap long text */
  white-space: normal; /* Allow the text to wrap onto the next line */
}

/* Adjust font size for longer text */
.class-name p {
  font-size: clamp(12px, 1vw, 16px); /* Dynamically adjust font size between 12px and 16px */
  margin: 0;
}

/* Assignment Info Section */
.assignment-info {
  padding: 15px;
  border-radius: 10px 0 0 10px;
  flex: 1; /* Allow the assignment info to take up the rest of the space */
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  height: 100%;
  text-align: center; /* Center text within this section */
}

.assignment-info h3 {
  margin: 0;
}

.assignment-info p {
  margin: 5px 0 0 0;
}


/* Start Button */
.start-button {
  background-color: rgb(241, 139, 2);
  color: white;
  padding: 10px 30px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  margin: 25px; /* Add margin to create space around the button */
  height: auto; /* Prevent button from stretching vertically */
  align-self: center;
  font-size: 18px;
}

.start-button:hover {
  background-color: #e08600; /* Darker orange on hover */
}


.classList {
  margin-top: 15px;
}

.assignmentsSection {
  flex-grow: 1;
}

.classTitle {
  flex: 2;
  text-align: left;
}
  

.classButton {
  background: none; /* Remove background color */
  color: black;
  padding: 10px;
  border: none;
  cursor: pointer;
  display: flex; /* Use flexbox for horizontal alignment */
  flex-direction: row; /* Align stars and class text in a row */
  align-items: center; /* Vertically align stars and text */
  width: 100%;
  text-align: left;
  font-size: 16px;
}

.classButton:hover {
  background-color: #f0f0f0;
}

.starSymbol {
  color: gold;
  font-size: 18px;
  margin-right: 10px; /* Add space between the star and the class text */
}

.classTextContainer {
  display: flex;
  flex-direction: column; /* Stack class name and assignment count vertically */
}

.className {
  font-weight: bold;
  color: black;
  font-size: 18px;
}

.assignmentCount {
  color: grey;
  font-size: 14px;
  margin-top: 4px;
}

/* Additional Styling for Tables */
table, th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.messageRow {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.fromServer {
  justify-content: flex-start;
}

.fromSelf {
  justify-content: flex-end;
}

.message {
  max-width: 70%;
  padding: 10px;
  margin: 5px;
  background-color: #f3f3f3;
  border-radius: 20px;
  white-space: pre-wrap;
}

nav#navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100vw - 40px);
  height: 80px;
  padding: 10px 20px;
  justify-content: space-between; /* Spread out the elements */
  background-color: rgb(255, 245, 230); /* Set background color */
}

/* Navbar Button Styling */
.navButton {
  background-color: rgb(241, 139, 2); /* Orange background for buttons */
  color: rgb(255, 245, 230); /* Set text color for buttons */
  padding: 10px 20px;
  border-radius: 25px; /* Round the button for a modern look */
  font-family: "Arial", sans-serif; /* Clean, professional font */
  font-weight: bold; /* Make the text stand out */
  font-size: 1.1em;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
  text-decoration: none; /* Remove underline from links */
}

/* Navbar Links Hover Effect */
.navButton:hover {
  background-color: #e67e22; /* Slightly darker orange on hover */
  transform: scale(1.05); /* Slight scaling for interactivity */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2); /* Add depth on hover */
}

/* Make Navbar Responsive */
@media (max-width: 768px) {
  nav#navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  nav#navbar .navButton {
    margin-top: 10px; /* Adjust margin for buttons on small screens */
    width: 100%; /* Make button full-width on small screens */
  }
}

.page {
  width: calc(100vw - 40px);
  height: calc(100vh - 120px);
  padding: 10px 20px;
}


h1, .page p {
  margin-block-end: 0;
  margin-block-start: 0;
}

.pageTitle {
  margin-block-start: 0;
  margin-bottom: 1em;
}

.login form {
  width: 275px;
}

.login form button {
  border: none;
  padding: 8px;
  border-radius: 10px;
  font-size: 16px;
}

.loginRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
}

.loginRow label {
  margin-left: 0;
  margin-right: auto;
  font-size: 18px;
}

.loginRow input {
  margin-right: 0;
  margin-left: auto;
  padding: 5px;
}

input:focus {
  outline: none;
}

.assignmentRow {
  display: flex;
  flex-direction: row;
  margin-top: 5px
}

.assignmentDescriptor:hover {
  background-color: #ddd;
  cursor: pointer;

}

.classTitle {
  width: 20vw;
}

.assignmentName {
  width: 25vw;
}

.assignmentDueDate {
  width: 20vw;
}

.assignmentCompletion {
  width: 15vw;
}

.chat {
  position: fixed;
}

.messageWrapper, .pastMessages {
  display: block;
  justify-content: flex-end;
}

.messageWrapper {
  height: calc(85vh - 160px);
  background-color: #eee;
  border-radius: 20px;
  padding: 20px;
  margin: 1em 0;
}

.pastMessages {
  height: calc(85vh - 250px);
  overflow-y: scroll;
}

.pastMessages * {
  overflow-anchor: none;
}

#scroll-anchor {
  overflow-anchor: auto;
  height: 1px;
}

.newMessageBox {
  height: 90px;
  background-color: white;
  align-items: center;
  justify-content: center;
  padding: 4px;
  align-self: flex-end;
}

.newMessage, .newAnswer {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.newMessageBox > .flexRow {
  width: 100%;
}

.newMessage {
  padding: 6px 2px;
  font-size: 1.1em;
  width: 80%;
  font-family: Arial, Helvetica, sans-serif;
  resize: none;
}

.errorMessage {
  color: #900;
}

.selectClass {
  padding: 20px;
  border-radius: 20px;
  border: solid 1px black;
}

.selectClass:hover {
  background-color: #ddd;
}

.sectionHeader {
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: 1px solid black;
  width: fit-content;
}

.teacherSection {
  margin-bottom: 1em;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.newQuestion {
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 15px;
}

.newQuestion.saved {
  background-color: #dfdfdf;
}

.newQuestion.unsaved {
  background-color: #f2f2f2;
}
.questionComponent, .objectName {
  align-items: center;
}
.questionComponent, .newQuestion > button {
  margin-bottom: 5px;
}

.questionComponent > input, .questionComponent > select, .paddedInput {
  margin-right: 8px;
  margin-left: 8px;
}

.actionButton {
  display: block;
  margin-bottom: 15px;
  border: none;
  padding: 5px 10px;
  border-radius: 10px;
}

.actionButton:hover {
  opacity: .9;
}

.actionButton:active {
  opacity: .7;
}

.deleteQuestionButton {
  margin-right: 0;
  margin-left: auto;
}

.assignmentProp {
  margin-left: 12px;
}

.assignmentPropRow {
  margin-bottom: 15px;
}

.graphManager input[type=number] {
  width: 75px;
}

.objectName {
  margin-left: 8px;
  border: 1px solid black;
  border-radius: 20px;
  padding: 0px 5px;
}

.objectName button {
  border: none;
  background: none;
}

.chatInputRow {
  align-items: center;
  margin: 2px;
}

/* Hide the MathLive virtual keyboard toggle */
math-field::part(virtual-keyboard-toggle) {
  display: none;
}

/* Hide the MathLive menu toggle */
math-field::part(menu-toggle) {
  display: none;
}

math-field {
  display: block;
  width: 40%;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

.pointTableCell {
  min-width: 20px;
  padding: 2px;
}

.pointTableCell input {
  width: 30px;
}

body {
  overflow-x: hidden;
}