.page {
    display: flex;
    gap: 20px;
    padding: 20px;
  }
  
  .welcomeBox {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 25%;
  }
  
  .contentWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .assignmentPropRow {
    margin-bottom: 10px;
  }

  .pageTitle {
    color: rgb(241, 139, 2);
    margin-block-start: 0;
    margin-bottom: 1em;
  }

  .dropdown {
    position: relative;
    width: 100%; /* Ensure it takes full width of its container */
    max-width: 500px;
}

.dropdownToggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: rgb(240, 240, 240);
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    position: relative;
}

.searchInput {
    background-color: rgb(240, 240, 240);
    flex: 1;
    border: none;
    outline: none;
}

.arrow {
    color: rgb(241, 139, 2);
    position: absolute;
    right: 10px;
    font-size: 16px;
}

.dropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: rgb(255, 255, 255);
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px; /* Set max height for scrollable effect */
    overflow-y: auto;
    z-index: 1000; /* Ensure it appears above other elements */
}

.dropdownItem {
    padding: 10px;
    cursor: pointer;
}

.dropdownItem:hover {
    background-color: #f0f0f0; /* Highlight on hover */
}

.subtitle {
    font-size: 17px; /* Control the font size */
    color: rgb(241, 139, 2); /* Control the text color */
    margin-bottom: 5px; /* Space between the label and dropdown */
    font-weight: bold; /* Make the label bold, optional */
    text-align: left; /* Align text to the left */
}

.exampleBox {
    background-color: #f0f0f0; /* Light gray background */
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc; /* Optional: adds a subtle border */
    margin-top: 10px;
  }

  .actionButton {
    background-color: rgb(241, 139, 2); /* Bright orange */
    color: white;
    border: none;
    padding: 10px 40px; /* Add some padding for button size */
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
  
  .actionButton:hover {
    background-color: #e65c00; /* Slightly darker orange for hover effect */
  }
  
  .buttonContainer {
    position: absolute;
    right: 20px; /* Distance from the right edge of the window */
    bottom: 20px; /* Adjust this based on how much space you want from the bottom */
  }

  .assignmentContainer {
    position: relative;
  }
  
  
  