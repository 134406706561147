/* body {
  overflow: hidden;
} */

.pageTitle {
  margin-bottom: -5px;
  margin-left: 1%;
  margin-top: -25px;
  font-size: 1.6rem; /* Use relative units for font size */
}

.chatContainer {
  position: fixed;
  left: 50%;
  transform: translateX(-50%); /* Centers the container */
  width: 95%; /* Adjusted for responsiveness */
  height: 80%;
  overflow: hidden;
  border: 2px solid orange;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.chat {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.pastMessages {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 10px;
  max-height: 100%;
  margin-bottom: 10px;
}

.scrollAnchor {
  height: 1px;
}

.newMessage,
.chatInputRow textarea {
  border: none;
  border-radius: 10px;
  background-color: #eee;
  width: 100%;
  box-sizing: border-box;
  resize: none;
  outline: none;
  font-size: 1rem; /* Relative font size */
  line-height: 1.5;
  margin-right: 30px;
  overflow-y: auto;
  max-height: 36px;
}

.newMessage::placeholder,
.chatInputRow textarea::placeholder {
  color: #737272;
  font-size: 1rem;
}

.textBoxWithButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #f9f9f9;
  padding: 10px;
  margin-right: 11px;
}

.textBoxWithButton textarea {
  flex-grow: 1;
  background-color: transparent;
  resize: none;
}

.textBoxWithButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.newMessageBox {
  height: auto;
  background-color: white;
  align-items: center;
  justify-content: center;
  padding: 4px;
  align-self: flex-end;
}

.newMessageBox > .flexRow {
  width: 100%;
}

.newMessage {
  padding: 6px 2px;
  width: 80%;
  font-family: Arial, Helvetica, sans-serif;
  resize: none;
}

.expressionSubmit {
  position: absolute;
  right: 0.5em;
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.messageRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 1rem;
}

.fromSelf {
  justify-content: flex-end;
}

.message {
  max-width: 70%;
  padding: 10px;
  margin: 5px;
  background-color: #f3f3f3;
  border-radius: 20px;
  white-space: pre-wrap;
}

.navigationButtons {
  display: flex;
  justify-content: space-between;
}

.navButton {
  background-color: rgb(241, 139, 2);
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.navButton:hover {
  background-color: #e67e22;
}

.prevButton {
  align-self: flex-start;
}

.nextButton {
  align-self: flex-end;
}

.navigationContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.currentQuestion {
  text-align: center;
  margin: 0;
  font-size: 1.25rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .pageTitle {
    font-size: 1.2rem;
  }

  .chatContainer {
    height: 90vh;
    width: 100%;
    padding: 5px;
  }

  .messageRow {
    font-size: 0.9rem;
  }

  .navButton {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .currentQuestion {
    font-size: 1rem;
  }
}